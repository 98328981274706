import React, { useEffect, useContext } from 'react';
import { Link, graphql } from 'gatsby';
import styled from 'styled-components';
import { withPrismicUnpublishedPreview } from 'gatsby-plugin-prismic-previews';

import { GlobalContext } from '../components/GlobalContext';

import Seo from '../components/Seo';

const NotFoundPage = ({ data }) => {
  const document = data.prismic404;
  const { message_text, button_text } = document.data;

  const { setStoryHeaderColor, setPageBgColor } = useContext(GlobalContext);

  useEffect(() => {
    setPageBgColor('var(--color-navy)');
    setStoryHeaderColor('#172A35');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Wrapper>
      <h1>{message_text}</h1>
      <Link to="/">{button_text}</Link>
    </Wrapper>
  );
};

export const Head = () => <Seo title="404: Not Found" />;

export default withPrismicUnpublishedPreview(NotFoundPage);

/**
 * Styled Components
 *
 */
const Wrapper = styled.div`
  padding-top: 9.6rem;
  height: 95vh;
  min-height: 40rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &:after {
    content: '';
    position: absolute;
    top: -20rem;
    width: 100vw;
    left: 50%;
    right: 50%;
    bottom: 0;
    margin-left: -50vw;
    margin-right: -50vw;
    background-color: var(--color-navy);
    z-index: -1;
  }

  h1 {
    width: 100%;
    max-width: 110rem;
    font-family: var(--font-family-national-compressed);
    font-size: clamp(4rem, 10vw, 12rem);
    line-height: 0.85;
    text-transform: uppercase;
    color: var(--color-snow);
    text-align: center;
    font-weight: var(--font-weight-extrabold);
    letter-spacing: 0.02em;
  }

  a {
    color: var(--color-baybee-blue);
    font-size: clamp(1.8rem, 5vw, 2.2rem);
    line-height: 1;
    font-weight: var(--font-weight-bold);
    text-align: center;
    margin: 6rem auto 0;
    display: inline-block;
    padding: var(--space-m) clamp(2rem, 5vw, 6rem);
    border: 0.1rem solid var(--color-baybee-blue);
    transition: all 150ms var(--ease-cubic-in-out);

    @media (hover: hover) {
      &:hover {
        background-color: var(--color-baybee-blue);
        color: var(--color-navy);
      }
    }
  }
`;

/**
 * GraphQL
 *
 */
export const query = graphql`
  query {
    prismic404 {
      _previewable
      data {
        message_text
        button_text
      }
    }
  }
`;
